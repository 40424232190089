import axios from 'axios';
import { apiUrl } from '@/config/config';
import Cookies from 'js-cookie';

export function configureFakeBackend() {
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        return new Promise((resolve, reject) => {
            // authenticate
            if (url.endsWith('/api/v1/auth/login') && opts.method === 'POST') {
                let params = JSON.parse(opts.body);
                axios.post(`${apiUrl}api/v1/auth/login`, params, { withCredentials: true })
                .then(response => {
                    if (response.data.data.access_token) {
                        const token = response.data.data.access_token;
                        Cookies.set('jwt', token, { path: '/', secure: false }); // Store JWT in cookies
                        let responseJson = { token };
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
                    } else {
                        reject(response.data.message);
                    }
                })
                .catch(error => {
                    reject(error.response.data.message);
                });
                return;
            }
            realFetch(url, opts).then(response => resolve(response));
        });
    }
}
