import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';
import axios from '@/helpers/axios';
import { apiUrl } from '@/config/config';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

async function checkAuth() {
  try {
      const response = await axios.post(`${apiUrl}api/v1/auth/check`);
      return response.data.authenticated;
  } catch (error) {
      return false;
  }
}

router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);

    // // // Check if user is authenticated
    
    // // If trying to access the login page while authenticated, redirect to home
    // if (to.path === '/login' && authenticated) {
      //     return next('/');
      // }
      
      // // If trying to access a protected page and not authenticated, redirect to login
      // if (authRequired && !authenticated) {
        //     return next('/login');
        // }
        
    if (authRequired) {
      const authenticated = await checkAuth();
      if (!authenticated) {
          return next('/login');
      }
    } 

    next();
});

export default router;
