import {jwtDecode} from 'jwt-decode';

import { defineStore } from 'pinia';
import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index';
import { useNotificationStore } from './notification';
import Cookies from 'js-cookie';

export const useAuthFakeStore = defineStore('authFake', {
    state: () => ({
        status: {},
        user: null,
    }),
    getters: {
        isAuthenticated: (state) => !!state.user,
        username: (state) => state.user?.username || '',
    },
    actions: {
        async login(email, password) {
            this.loginRequest(email);
            try {
                const response = await userService.login(email, password);

                // Decode the JWT token to extract user information
                const decodedToken = jwtDecode(response.token);

                this.loginSuccess(decodedToken);
                router.push('/'); // Ensure this redirects to the desired route
            } catch (error) {
                this.loginFailure();
                const notificationStore = useNotificationStore();
                notificationStore.error(error);
            }
        },
        logout() {
            userService.logout();
            this.status = {};
            this.user = null;
            router.push('/login'); // Redirect to login page on logout
        },
        loginRequest(email) {
            this.status = { loggingIn: true };
            this.user = email;
        },
        loginSuccess(decodedToken) {
            this.status = { loggeduser: true };
            this.user = {
                username: decodedToken.username,
                role: decodedToken.role,
                status: decodedToken.status,
                exp: decodedToken.exp,
                iat: decodedToken.iat
            };
            // Calculate the expiration time for the cookie
            const expiresIn = decodedToken.exp * 1000 - Date.now(); // Convert from seconds to milliseconds

            // Set the cookie with the calculated expiration
            Cookies.set('userData', JSON.stringify(this.user), { expires: new Date(Date.now() + expiresIn), path: '/', secure: false });
        },
        loginFailure() {
            this.status = {};
            this.user = null;
        },
    },
});
