import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from '@/helpers/axios';
import { apiUrl } from '@/config/config';

export const userService = {
    login,
    logout,
};

async function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        credentials: 'include' // Ensure cookies are included
    };

    return fetch(`${apiUrl}api/v1/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.access_token) {
                const decodedToken = jwtDecode(user.access_token);
                const userWithDecodedToken = { ...user, ...decodedToken };
                return userWithDecodedToken;
            }
            return user;
        });
}

async function logout() {
    try {
      const response = await axios.post(`${apiUrl}api/v1/auth/logout`, {}, { withCredentials: true });
      if (response.data.message === 'Logout successful') {
        Cookies.remove('jwt');
        Cookies.remove('userData');
        // Redirect to login page or home page
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                location.reload(true);
            }
            const error = (data?.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
