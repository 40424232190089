// import axios from 'axios';

// // Set default axios configurations
// axios.defaults.withCredentials = true;

// export default axios;


import axios from 'axios';
import Cookies from 'js-cookie';

// Set default axios configurations
axios.defaults.withCredentials = true;

// Add an interceptor to set the Authorization header with Bearer token
axios.interceptors.request.use((config) => {
  const token = Cookies.get('jwt'); // Get JWT from cookies
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Set Bearer token in Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;